import ButtonBase from 'atoms/button-base';

export default function Button({ 
    type = "primary",
    link,
    children,
    gtmIdentifier = null,
    ...props
}) {
    let classes = ["btn"]
    switch (type) {
        case "primary":
            classes.push("btn-primary")
            break;

        case "secondary":
            classes.push("btn-secondary")
            break;

        case "secondary-purple":
            classes.push("btn-secondary-purple-text")
            break;

        case "tertiary":
            classes.push("btn-tertiary")
            break;

        case "inline":
            classes.push("btn-primary inline")
            break;

        case "plain":
            classes.push("btn-plain")
            break;

        case "active":
            classes.push("btn-active")
            break;

        case "primary-no-margin":
            classes.push("btn-primary")
            classes.push("no-margin")
            break;

        case "orange":
            classes.push("btn-orange")
        default:
            break;
    }

    if(gtmIdentifier) {
        classes.push(gtmIdentifier)
    }

    return (
        <>
            <ButtonBase
              link={link}
              className={classes.join(" ")}
              {...props}
            >{children}</ButtonBase>

            <style global jsx>{`
                .btn {
                    color: #ffffff;
                    border: none;
                    font-size: 12px;
                    padding: 8px 15px;
                    font-weight: 500;
                    margin: 10px 0 0 0;
                    font-weight: 700;
                    word-wrap: break-word;
                }

                .no-margin {
                    margin: 0;
                }

                .btn-plain {
                    color: #000000;
                }

                .btn-plain.active {
                    border-bottom: 1px solid #102E4A;
                    border-radius: 0;
                }
            
                .btn.btn-primary.inline {
                    display: inline-block;
                    margin-right: 20px;
                }

                .btn.btn-primary {
                    color: #ffffff;
                    background-color: #102E4A;
                    text-transform: uppercase;
                    border-radius: 5px;
                }

                .btn.btn-active {
                    color: #000000;
                    background-color: #f5f5f5;
                    border-radius: 5px;
                    margin-right: 20px;
                    text-transform: uppercase;
                }
                
                .btn.btn-secondary {
                    color: #102E4A;
                    background-color: #F5F5F5;
                    text-transform: uppercase;
                    border-radius: 5px;
                }

                .btn.btn-secondary-purple-text {
                    color: #715aff;
                    background-color: #F5F5F5;
                    border-radius: 5px;
                }
                
                .btn.btn-tertiary {
                    color: #ffffff;
                    background: #102E4A;
                    border-radius: 5px;
                    width: 100%;
                    padding: 12px 20px;
                    text-transform: uppercase;
                }
                
                .btn.btn-primary:hover {
                    color: #ffffff !important;
                    background-color: #153f66;
                }
                
                .btn.btn-secondary:hover {
                    color: #102E4A;
                }
                
                .btn.btn-tertiary:hover {
                    color: #ffffff !important;
                }
            `}</style>
        </>
    )
}


